<app-header *ngIf="isUserLoggedIn"></app-header>
<app-unauth-header *ngIf="!isUserLoggedIn"></app-unauth-header>
<main>
    <section id="accessibility">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-8 offset-md-2">
                    <h2 class="margin-b30">Random Introduction about how accessible our site is...</h2>
                    <p class="text-justify">Construction technology refers to the collection of innovative tools, machinery, modifications, software, etc. used during the construction phase of a project that enables advancement in field construction methods, including semi-automated and automated construction equipment</p>
                    <p class="text-justify">Construction technology refers to the collection of innovative tools, machinery, modifications, software, etc. used during the construction phase of a project that enables advancement in field construction methods, including semi-automated and automated construction equipment</p>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>