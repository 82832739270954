<app-header *ngIf="userLoggedIn"></app-header>
<app-unauth-header *ngIf="!userLoggedIn"></app-unauth-header>
 <main>
    <section class="page-not-found">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12 col-md-6 offset-md-3">
                    <img src="assets/images/page_not_found.png" alt="Page Not Found" class="img-responsive img-custom">
                    <h5>{{ statusMessage }}</h5>
                    <h5>Go back to <a (click)="redirectUser()" class="error-link">Home Page</a></h5>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>