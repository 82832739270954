<div class="row filter-n-sort">
    <div class="col-xs-12 col-md-4 offset-md-2">
        <p class="filter-n-sort-title">Filter:</p>
        <div class="btn-group" role="group" aria-label="Filter">
            <button type="button" class="btn btn-light">Left</button>
            <button type="button" class="btn btn-light">Middle</button>
            <button type="button" class="btn btn-light">Right</button>
        </div>
    </div>
    <div class="col-xs-12 col-md-4 offset-md-2">
        <p class="filter-n-sort-title">Sort:</p>
        <div class="btn-group" role="group" aria-label="Sort">
            <button type="button" class="btn btn-light">Left</button>
            <button type="button" class="btn btn-light">Middle</button>
            <button type="button" class="btn btn-light">Right</button>
        </div>
    </div>
</div>