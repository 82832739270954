<form (ngSubmit)="saveProjectDetails(form)" #form="ngForm">
    <div class="form-group">
        <label for="project_name">Project Name:</label>
        <input type="text" class="form-control" placeholder="Project Name" name="project_name" ngModel required #projectNameInput="ngModel">
        <span class="help-block" *ngIf="!projectNameInput.valid && projectNameInput.touched">
            Please enter a valid project-name
        </span>
    </div>
    <div class="form-group">
        <button class="btn btn-dark left" (click)="cancelAddition()" type="button">Cancel</button>
        <button class="btn btn-dark right" type="submit" [disabled]="!form.valid">Add</button>
    </div>
</form>
