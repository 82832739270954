import { Injectable } from '@angular/core';

@Injectable()
export class ProjectFilesService {

constructor() { }

/* logic to upload files
		this.projectFilesService.....
	*/
}
