<section class="project-dpr">
    <div class="container-fluid">
        <h2 class="text-center margin-b30">Daily Progress Report</h2>

        <!-- <div class="row filter-n-sort">
            <div class="col-xs-12 col-md-4 offset-md-2">

            </div>
            <div class="col-xs-12 col-md-4 offset-md-2">
                <p class="filter-n-sort-title">Sort:</p>
                <div class="btn-group" role="group" aria-label="Sort">
                    <button type="button" (click)="sort('asc', 'date')" class="btn btn-light"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    <button type="button" class="btn btn-light disabled">Modified Date</button>
                    <button type="button" (click)="sort('desc', 'date')" class="btn btn-light"><i class="fa fa-arrow-down" aria-hidden="true"></i></button>
                </div>
            </div>
        </div> -->

        <div class="row btn-add-project">
            <button class="btn" (click)="addNewDPR()">
                <i class="fa fa-plus-circle fa-4x" aria-hidden="true"></i>
            </button>
        </div>

        <div class="row">
            <div class="offset-md-2 col-md-8 col-xs-12">
                <app-add-dpr *ngIf="isDataAdding && !isDataUpdating"></app-add-dpr>

                <app-edit-dpr *ngIf="isDataUpdating && !isDataAdding" [updateDataForDate]="currentKey" [updateForContents]="currentValue"></app-edit-dpr>

                <ng-container *ngIf="!isDataUpdating && !isDataAdding">
                    <div class="card margin-b30" *ngFor="let item of tempMap | keyvalue: maintainOrder">
                        <div class="card-header date">{{ item.key | date: 'EEEE, d MMMM yyyy' }}</div>
                        <div class="card-body">
                            <span class="card-text">
                                <ol class="dpr-list">
                                    <li *ngFor="let listItem of item.value">{{ listItem }}</li>
                                </ol>
                            </span>
                        </div>
                        <div class="card-footer">
                            <button (click)="updateProjectDPR(item.key, item.value)" class="btn btn-dark right">Update</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>