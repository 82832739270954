<app-header *ngIf="isUserLoggedIn"></app-header>
<app-unauth-header *ngIf="!isUserLoggedIn"></app-unauth-header>
<main>
    <section id="contact-us">
        <div class="container mt-5">
            <div class="row">
              <div class="col-md-6 mx-auto">
                <div class="card">
                  <div class="card-header">
                    Contact Information
                  </div>
                  <div class="card-body">
                    <div class="contact-info">
                      <p><strong>Phone:</strong> +91-98201 35251</p>
                      <p><strong>Email:</strong> ashishmenda@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>