<app-header></app-header>
    <main>
        <section class="my-profile">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-12 col-md-8 offset-md-2">
                        <h2 class="text-center">My Profile</h2>
                        <h4>Personal Details</h4>
                        <div class="form-group">
                            <label for="userName">Name: </label> {{ userName }}
                            <br>
                            <input type="text" *ngIf="updateMessageForName" class="form-control" name="userName" #userNameInput>
                            <span class="help-block" *ngIf="userNameErrorMessage.length > 0">{{ userNameErrorMessage }}</span>
                            <a (click)="updateUserValueFor('name')" *ngIf="updateMessageForName" class="update-profile-details">Update | </a>
                            <a (click)="updateDisplayMessageFor('name')" class="update-profile-details">{{ nameMessage }}</a>
                        </div>
                        <div class="form-group">
                            <label for="userEmail">Email: </label> {{ userEmail }}
                            <br>
                            <input type="text" *ngIf="updateMessageForEmail" class="form-control" name="userEmail" #userEmailInput>
                            <span class="help-block" *ngIf="userEmailErrorMessage.length > 0">{{ userEmailErrorMessage }}</span>
                            <a (click)="updateUserValueFor('email')" *ngIf="updateMessageForEmail" class="update-profile-details">Update | </a>
                            <a (click)="updateDisplayMessageFor('email')" class="update-profile-details">{{ emailMessage }}</a>
                        </div>

                        <h4>Change Password</h4>
                        <div class="form-group">
                            <label for="userName">Current Password</label>
                            <input type="password" class="form-control" id="current_password" name="current_password" #currentPassword>
                        </div>
                        <div class="form-group">
                            <label for="userEmail">New Password</label>
                            <input type="password" class="form-control" id="new_password" name="new_password" #newPassword>
                        </div>
                        <div class="form-group">
                            <label for="userName">Retype New Password</label>
                            <input type="password" class="form-control" id="new_password_confirm" name="new_password_confirm" #confirmNewPassword>
                            <span class="help-block" *ngIf="userPasswordErrorMessage.length > 0">{{ userPasswordErrorMessage }}</span>
                            <a (click)="updateUserValueFor('password')" class="update-profile-details">Update</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
<app-footer></app-footer>