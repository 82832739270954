<app-unauth-header></app-unauth-header>
<main>
    <section id="introduction">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-8 offset-md-2">
                    <h2>Random Introduction about our Product</h2>
                    <p>Construction technology refers to the collection of innovative tools, machinery, modifications, software, etc. used during the construction phase of a project that enables advancement in field construction methods, including semi-automated and automated construction equipment</p>
                    <p>Construction technology refers to the collection of innovative tools, machinery, modifications, software, etc. used during the construction phase of a project that enables advancement in field construction methods, including semi-automated and automated construction equipment</p>
                    <hr>
                </div>
            </div>
        </div>
    </section>
    <section id="authenticate">
        <div class="container">
            <div class="row" *ngIf="errorMessage.length > 0">
                <div class="col-xs-12 col-md-6 offset-md-3">
                    <app-alert-widget [message]="errorMessage" *ngIf="errorMessage.length > 0" (closePopUp)="closePopUp()"></app-alert-widget>
                </div>
            </div>
            <div class="row">
                <ng-template #loadingSpinner>
                    <div class="col-xs-6 offset-xs-6 col-sm-6 offset-sm-6 col-md-6 offset-md-6">
                        <app-loading-spinner></app-loading-spinner>
                    </div>
                </ng-template>
                <div class="col-xs-12 col-md-6 offset-md-3">
                    <h2 class="heading-center">{{ isLoginMode ? 'Login' : 'Register' }}</h2><hr>
                    <form (ngSubmit)="onSubmit(form)" #form="ngForm"  *ngIf="!isLoading; else loadingSpinner">
                        <div class="form-group" *ngIf="!isLoginMode">
                            <label for="username">Name</label>
                            <input type="text" id="username" class="form-control" name="username" required ngModel #userNameInput="ngModel" appUserNameValidator>
                            <span class="help-block" *ngIf="!userNameInput.valid && userNameInput.touched">
                                <span class="help-block" *ngIf="userNameInput?.errors?.uname_already_exists">User Name already exists...</span>
                                <span class="help-block" *ngIf="userNameInput?.errors?.uname_length_invalid">User Name length should be greater than 4!</span>
                                <span class="help-block" *ngIf="userNameInput?.errors?.required">Please enter a valid user-name</span>
                            </span>
                        </div> 

                        <div class="form-group">
                            <label for="email">E-Mail</label>
                            <input type="email" id="email" class="form-control" name="email" required ngModel email #emailInput="ngModel" [appEmailValidator]="!isLoginMode ? true : false">
                            <span class="help-block" *ngIf="!emailInput.valid && emailInput.touched">
                                <span class="help-block" *ngIf="emailInput?.errors?.uemail_already_exists">Email already exists...</span>
                                <span class="help-block" *ngIf="(!emailInput.valid && !emailInput?.errors?.uemail_already_exists) || emailInput?.errors?.required">Please enter a valid email</span>
                            </span>
                        </div>

                        <div class="form-group">
                            <label for="password">Password</label>
                            <!-- <input type="password" id="password" [attr.class]="formClass" name="password" required ngModel #passwordInput="ngModel" appPasswordValidator> -->
                            <input type="password" id="password" class="form-control" name="password" required ngModel #passwordInput="ngModel" appPasswordValidator>
                            <span *ngIf="!isLoginMode" class="fa fa-question-circle fa-xl" tooltip="Password should be between 8 - 16 character length and must include special character and number" placement="bottom" delay="500"></span>
                            <span class="help-block" *ngIf="!passwordInput.valid && passwordInput.touched">{{ isLoginMode ? 'Please enter a valid password' : 'Password should be between 8 - 16 character length and must include special character and number'}}</span>
                        </div>

                        <div class="form-group">
                            <button class="btn btn-dark" type="submit" [disabled]="!form.valid">{{ isLoginMode ? 'Login' : 'Register' }}</button> |
                            <button class="btn btn-dark" (click)="switchMode()" type="button">Switch to {{ isLoginMode ? 'Register' : 'Login' }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>