import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-n-sort',
  templateUrl: './filter-n-sort.component.html',
  styleUrls: ['./filter-n-sort.component.css']
})
export class FilterNSortComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
