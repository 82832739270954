import { Component, OnInit } from '@angular/core';
import {AuthService} from '../authentication/auth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  constructor(
    private authService: AuthService
  ) { }

  isUserLoggedIn: boolean = false;
  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.authService.user.subscribe((userData) => {
      this.isUserLoggedIn = (!!userData) ? true : false;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
