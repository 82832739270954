<header>
    <div id="wrapper" class="animate">
        <nav class="navbar header-top fixed-top navbar-expand-lg navbar-dark bg-dark">
            <span *ngIf="isNormalHeader" class="navbar-toggler-icon leftmenutrigger" (click)="toggleSideNav()"></span>
            <a class="navbar-brand" (click)="navigateToHomePage()">iSITE</a>
            <!--toggle class 'collapsed'-->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!--toggle class 'show'-->
            <div class="collapse navbar-collapse" id="navbarText">
                <!--toggle class 'open'-->
                <ul *ngIf="isNormalHeader" [attr.class]="spanSideNavClass" (click)="toggleClassValue($event)">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/project/details">{{ currentProjectName }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/project/plan" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Plan
                            <span class="sr-only">(current)</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/project/photos" routerLinkActive="active">Photos</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/project/dpr" routerLinkActive="active">DPR</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/project/material-inward" routerLinkActive="active">Material Inward</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/project/files" routerLinkActive="active">Files</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/project/teams" routerLinkActive="active">Team</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/project/bg-details" routerLinkActive="active">BG Details</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" routerLink="/project/bg-" routerLinkActive="active">wrong url</a>
                    </li> -->
                </ul>
                <ul class="navbar-nav ml-md-auto d-md-flex">
                    <li class="dropdown">
                        <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{ userName }} <span class="caret"></span></a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-nav-link" routerLink="/my-account">Profile</a></li>
                            <li><a class="dropdown-nav-link" (click)="logoutUser()">Logout</a></li>
                        </ul>
                    </li>
                </ul>
                <form class="navbar-form navbar-left">
                    <input type="text" class="form-control" placeholder="Help me find...">
                    <button type="submit" class="btn btn-search">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </form>
            </div>
        </nav>
    </div>
</header>