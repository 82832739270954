import { Component } from '@angular/core';

@Component({
  selector: 'app-unauth-header',
  templateUrl: './unauth-header.component.html',
  styleUrls: ['./unauth-header.component.css']
})
export class UnauthHeaderComponent {

  constructor() { }

}
