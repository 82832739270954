<footer>
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                <ul class="list-unstyled list-inline social text-right">
                    <li class="list-inline-item"><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li class="list-inline-item"><a href="#"><i class="fa fa-twitter"></i></a></li>
                    <li class="list-inline-item"><a href="#"><i class="fa fa-instagram"></i></a></li>
                    <li class="list-inline-item"><a href="#" target="_blank"><i class="fa fa-envelope"></i></a></li>
                </ul>
            </div>
        </div>	
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-right">
                <ul class="list-unstyled list-inline legal">
                    <li class="list-inline-item"><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li class="list-inline-item"><a routerLink="/legal-notice">Legal Notice</a></li>
                    <li class="list-inline-item"><a routerLink="/accessibility">Accessibility Statement</a></li>
                </ul>
                <p class="h6">
                    <i class="fa fa-copyright" aria-hidden="true"></i> 
                    All right Reversed.<a class="ml-2" routerLink="/">iSITE</a>
                </p>
            </div>
        </div>	
    </div>
</footer>